import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAx6TYHp0zqVQqLcZ_RarXt21HT56sYbFg",
  authDomain: "personalportfolio-f189c.firebaseapp.com",
  databaseURL: "https://personalportfolio-f189c-default-rtdb.firebaseio.com",
  projectId: "personalportfolio-f189c",
  storageBucket: "personalportfolio-f189c.appspot.com",
  messagingSenderId: "526616079858",
  appId: "1:526616079858:web:1e2c961371ca3e39378f6b",
  measurementId: "G-1WVV0NH7XP",
};
//initialize firebase
const app = initializeApp(firebaseConfig);
//reference for database
const db = getFirestore(app);

export { app, db };
